import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import MinerConfig from 'components/miner-config/miner-config';

import { MainContentArea, Container } from 'assets/styles/pages.style';

export default function LuckPage() {
  const { t } = useTranslation();
  return (
    <MainContentArea>
      <Container>
        <MinerConfig
          title={t('minersConfigBlock.headTitle')}
          mode="toggler"
          justifyContent="flex-start"
        />
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.minersetting.title')} />;
};
